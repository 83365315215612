<template>
  <v-container fill-height class="pa-0">
    <v-container>
      <v-card class="text-center" flat color="transparent">
        <v-progress-circular
          color="primary"
          indeterminate
        ></v-progress-circular>
        <v-card-title class="justify-center font-weight-bold title">
          잠시만 기다려주세요.
        </v-card-title>
        <v-card-subtitle>
          보안을 위해 브라우저를 확인 중입니다
        </v-card-subtitle>
      </v-card>
    </v-container>
  </v-container>
</template>
<script>
import API from "../../../API/auth";
/* eslint-disable no-unused-vars */
if (!window.Kakao.isInitialized()) {
  window.Kakao.init(process.env.VUE_APP_KAKAO_APP_KEY_WEB);
  window.Kakao.isInitialized();
}

export default {
  data() {
    return {
      token: "",
    };
  },
  async mounted() {
    const kakaoAuthCode = this.$route.query.code;
    if (kakaoAuthCode) {
      try {
        const result = await this.$axios.post("auth/kakaoLogin", {
          code: kakaoAuthCode,
        });
        var kakaoToken = result.data.kakao_token;
        var fireToken = result.data.firebase_token;
        this.$firebase
          .auth()
          .setPersistence(this.$firebase.auth.Auth.Persistence.SESSION)
          .then(async () => {
            try {
              const tokenResult = await this.$firebase
                .auth()
                .signInWithCustomToken(fireToken);

              this.token = kakaoToken;
              window.Kakao.Auth.setAccessToken(kakaoToken);

              const user = tokenResult.user;
              await this.$store.dispatch("auth/user/SET_USER", user);
              API.checkUserValid();
            } catch (error) {
              alert(
                "토큰이 정상적이지 않습니다. 만료된 토큰이거나 이미 사용된 계정입니다.",
              );
            }
          });
      } catch (e) {
        alert("문제가 발생했습니다. 이미 가입된 이메일일 수 있습니다.");
        this.$router.push({ name: "Login" });
      }
    } else {
      alert("잘못된 방법으로 접근하셨습니다. 로그인 페이지로 이동합니다.");
      this.$router.push({ name: "Login" });
    }
  },
  methods: {},
};
</script>
